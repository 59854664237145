import {
    createWebHistory,
    createRouter
} from "vue-router";
import {
    defineAsyncComponent
} from "vue";

const MainView = defineAsyncComponent(() =>
    import("@/views/main.vue"));

const Login = defineAsyncComponent(() =>
    import("@/views/Login/login.vue"));

const ForgotPassword = defineAsyncComponent(() =>
    import("@/views/Login/forgot-password.vue")
);
const Dashboard = defineAsyncComponent(() =>
    import("@/views/Dashboard/dashboard.vue")
);

const Users = defineAsyncComponent(() =>
    import("@/views/User/users.vue"));

const UsersDetail = defineAsyncComponent(() =>
    import("@/views/User/user-details.vue")
);

const DependentDetail = defineAsyncComponent(() =>
    import("@/views/User/dependent-detail.vue")
);

const AddUser = defineAsyncComponent(() =>
    import("@/views/User/user-details.vue")
);


const Doctor = defineAsyncComponent(() =>
    import("@/views/Doctor/doctor.vue"));

const DoctorDetail = defineAsyncComponent(() =>
    import("@/views/Doctor/doctor-details.vue")
);

const AddDoctor = defineAsyncComponent(() =>
    import("@/views/Doctor/doctor-details.vue")
);

const AddDependent = defineAsyncComponent(() =>
    import("@/views/User/dependent-detail.vue")
);

const UserProfile = defineAsyncComponent(() =>
    import("@/views/User/profile.vue")
);

const Roles = defineAsyncComponent(() =>
    import("@/views/Roles/roles.vue")
);

const AddRole = defineAsyncComponent(() =>
    import("@/views/Roles/add-role.vue")
);

const Admin = defineAsyncComponent(() =>
    import("@/views/Admin/admin.vue")
);

const AddAdmin = defineAsyncComponent(() =>
    import("@/views/Admin/add-admin.vue")
);

const Service = defineAsyncComponent(() =>
    import("@/views/Service/service.vue")
);

const AddService = defineAsyncComponent(() =>
    import("@/views/Service/add-service.vue")
);

const Language = defineAsyncComponent(() =>
    import("@/views/Language/language.vue")
);

const AddLanguage = defineAsyncComponent(() =>
    import("@/views/Language/add-language.vue")
);


const ChangePassword = defineAsyncComponent(() =>
    import("@/views/User/change-password.vue")
);

const StaticPage = defineAsyncComponent(() =>
    import("@/views/Static Page/static-page.vue")
);

const AddStaticPage = defineAsyncComponent(() =>
    import("@/views/Static Page/add-static-page.vue")
);

const Subscription = defineAsyncComponent(() =>
    import("@/views/Subscription/subscription.vue")
);

const AddSubscription = defineAsyncComponent(() =>
    import("@/views/Subscription/add-subscription.vue")
);

const Expertise = defineAsyncComponent(() =>
    import("@/views/Expertise/expertise.vue")
);

const AddExpertise = defineAsyncComponent(() =>
    import("@/views/Expertise/add-expertise.vue")
);


const Banner = defineAsyncComponent(() =>
    import("@/views/Banner/banner.vue")
);

const AddBanner = defineAsyncComponent(() =>
    import("@/views/Banner/add-banner.vue")
);

const Vital = defineAsyncComponent(() =>
    import("@/views/Vital/vitals.vue")
);

const VitalInformation = defineAsyncComponent(() =>
    import("@/views/Vital/vital-details.vue")
);

const AddVital = defineAsyncComponent(() =>
    import("@/views/Vital/update-vital.vue")
);

const UpdateVitalInformation = defineAsyncComponent(() =>
    import("@/views/Vital/update-vital-information.vue")
);

const Notification = defineAsyncComponent(() =>
    import("@/views/Notification/notifications.vue")
);

const NotificationUser = defineAsyncComponent(() =>
    import("@/views/Notification/notification-user.vue")
);

const AddNotification = defineAsyncComponent(() =>
    import("@/views/Notification/add-notification.vue")
);


const Setting = defineAsyncComponent(() =>
    import("@/views/Setting/setting.vue")
);

const Health = defineAsyncComponent(() =>
    import("@/views/Health/health.vue")
);

const AddHealth = defineAsyncComponent(() =>
    import("@/views/Health/add-health.vue")
);

const Faq = defineAsyncComponent(() =>
    import("@/views/Faq/faq.vue")
);

const AddFaq = defineAsyncComponent(() =>
    import("@/views/Faq/add-faq.vue")
);

const Course = defineAsyncComponent(() =>
    import("@/views/Course/course.vue")
);
const CourseDetail = defineAsyncComponent(() =>
    import("@/views/Course/course-details.vue")
);

const AppointmentDetail = defineAsyncComponent(() =>
    import("@/views/Doctor/appointment-details.vue")
);


const routes = [{
        path: "/",
        redirect: Login,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "",
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true,
        },
        children: [{
                path: "/profile",
                name: "User Profile",
                component: UserProfile,
            },
            {
                path: "/dashboard",
                name: "DashboardPage",
                component: Dashboard,
            },

            {
                path: "/vitals",
                name: "Vital",
                component: Vital,
            },
            {
                path: "/vital-information/:code",
                name: "VitalInformation",
                component: VitalInformation,
            },
            {
                path: "/update-vital/:code",
                name: "AddVital",
                component: AddVital,
            },
            {
                path: "/update-vital-information/:code",
                name: "UpdateVitalInformation",
                component: UpdateVitalInformation,
            },

            {
                path: "/users",
                name: "User",
                component: Users,
            },
            {
                path: "/user-details",
                name: "User Details",
                component: UsersDetail,
            },
            {
                path: "/add-user",
                name: "Add User",
                component: AddUser,
            },
            {
                path: "/add-dependent",
                name: "Add Dependent",
                component: AddDependent,
            },
            {
                path: "/user-dependents",
                name: "User Dependents",
                component: DependentDetail,
            },
            {
                path: "/doctors",
                name: "Doctor",
                component: Doctor,
            },
            {
                path: "/doctor-details",
                name: "Doctor Details",
                component: DoctorDetail,
            },
            {
                path: "/add-doctor",
                name: "Add Doctor",
                component: AddDoctor,
            },
            {
                path: "/roles",
                name: "Roles",
                component: Roles,
            },

            {
                path: "/admins",
                name: "Admin",
                component: Admin,
            },
            {
                path: "/add-admin",
                name: "AddAdmin",
                component: AddAdmin,
            },
            {
                path: "/update-admin",
                name: "UpdateAdmin",
                component: AddAdmin,
            },

            {
                path: "/change-password",
                name: "ChangePassword",
                component: ChangePassword,
            },

            {
                path: "/services",
                name: "Service",
                component: Service,
            },
            {
                path: "/add-service/:code",
                name: "AddService",
                component: AddService,
            },
            {
                path: "/update-service/:code",
                name: "UpdateService",
                component: AddService,
            },




            {
                path: "/languages",
                name: "Language",
                component: Language,
            },
            {
                path: "/add-language",
                name: "AddLanguage",
                component: AddLanguage,
            },
            {
                path: "/update-language",
                name: "UpdateLanguage",
                component: AddLanguage,
            },
            {
                path: "/add-role",
                name: "AddRole",
                component: AddRole,
            },
            {
                path: "/update-role",
                name: "UpdateRole",
                component: AddRole,
            },

            {
                path: "/static_pages",
                name: "StaticPage",
                component: StaticPage,
            },
            {
                path: "/add-static-page",
                name: "AddStaticPage",
                component: AddStaticPage,
            },
            {
                path: "/update-static-page/:code",
                name: "UpdateStaticPage",
                component: AddStaticPage,
            },

            {
                path: "/subscriptions",
                name: "Subscription",
                component: Subscription,
            },
            {
                path: "/add-subscription/:code",
                name: "AddSubscription",
                component: AddSubscription,
            },
            {
                path: "/update-subscription/:code",
                name: "UpdateSubscription",
                component: AddSubscription,
            },

            {
                path: "/expertises",
                name: "Expertise",
                component: Expertise,
            },
            {
                path: "/add-expertise/:code",
                name: "AddExpertise",
                component: AddExpertise,
            },
            {
                path: "/update-expertise/:code",
                name: "UpdateExpertise",
                component: AddExpertise,
            },


            {
                path: "/banners",
                name: "Banner",
                component: Banner,
            },
            {
                path: "/add-banner/:code",
                name: "AddBanner",
                component: AddBanner,
            },
            {
                path: "/update-banner/:code",
                name: "UpdateBanner",
                component: AddBanner,
            },

            {
                path: "/notifications",
                name: "Notification",
                component: Notification,
            },
            {
                path: "/notification-user",
                name: "NotificationUser",
                component: NotificationUser,
            },
            {
                path: "/add-notification",
                name: "AddNotification",
                component: AddNotification,
            },
            {
                path: "/setting",
                name: "Setting",
                component: Setting,
            },
            {
                path: "/health",
                name: "Health",
                component: Health,
            },
            {
                path: "/add-health/:code",
                name: "AddHealth",
                component: AddHealth,
            },
            {
                path: "/update-health/:code",
                name: "UpdateHealth",
                component: AddHealth,
            },

            {
                path: "/faq",
                name: "Faq",
                component: Faq,
            },
            {
                path: "/add-faq/:code",
                name: "AddFaq",
                component: AddFaq,
            },
            {
                path: "/update-faq/:code",
                name: "UpdateFaq",
                component: AddFaq,
            },

            {
                path: "/courses",
                name: "Course",
                component: Course,
            },
            {
                path: "/add-course",
                name: "AddCourseDetails",
                component: CourseDetail,
            },
            {
                path: "/update-course",
                name: "UpdateCourseDetails",
                component: CourseDetail,
            },
            
            {
                path: "/appointment-details",
                name: "AppointmentDetails",
                component: AppointmentDetail,
            },



        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("access_token") == null) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        if (to.path === "/" || to.path === "/login") {
            if (localStorage.getItem("access_token") != null) {
                next({
                    path: "/profile",
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router;