<template>
  <router-view></router-view>
</template>

<script>
import './assets/css/style.css';
import './assets/css/font-awesome.min.css';
import './assets/css/sidebar.css';
import 'element-plus/theme-chalk/display.css';

export default {
  name: 'App',
  components: {},
  mounted() {
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const ctx = this;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const OriginalResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends OriginalResizeObserver {
      constructor(callback) {
        const debouncedCallback = debounce(callback, 20);
        super(debouncedCallback);
      }
    };
  }
};
</script>
