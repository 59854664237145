import {
    defineRule
} from "vee-validate";

defineRule("required", (value, [name, isSelect]) => {
    if (!value || value.length <= 0) {
        if (name) {
            if (!isSelect || isSelect == false) {
                return `Please enter ${name}`;
            } else {
                return `Please select ${name}`;
            }
        }
        return "This field is required";
    }
    return true;
});

defineRule("message", (value, [name]) => {
    if (value && value == "select") {
        return `Please select ${name}`;
    }
    return true;
});

defineRule("email", (value) => {
    if (
        value &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        )
    ) {
        return "Please enter valid email";
    }
    return true;
});

defineRule("phone", (value) => {
    if (value && !/^\d{8,12}$/.test(value)) {
        return "Please enter valid contact number";
    }
    return true;
});
defineRule("phone_number", (value) => {
    if (value && !/^\d{6,17}$/.test(value)) {
        return "Please enter a valid contact number between 6 and 17 digits";
    }
    return true;
});


defineRule("pincode", (value) => {
    if (value && !/^[0-9]{6}$/.test(value)) {
        return "Please enter valid pin code";
    }
    return true;
});

defineRule("userName", (value, [name]) => {
    if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
        if (name) {
            return `Please enter valid ${name}`;
        }
        return "Please enter valid name";
    }
    return true;
});

defineRule("minLength", (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule("password", (value) => {
    if (value && !/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) {
        return "Password must contain at least 1 alphabet, 1 digit and min 8 character length";
    }
    return true;
});

defineRule("confirmed", (value, [target]) => {
    if (value === target) {
        return true;
    }
    return "Passwords must match";
});

defineRule("price", (value) => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter valid price";
    }
    return true;
});

defineRule("numeric", (value) => {
    if (value && !/^[0-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter numeric values only";
    }
    return true;
});

defineRule("digit", (value) => {
    if (value && !/^[0-9]\d*?$/.test(value) && (value < 1)) {
        return "Please enter numeric values only";
    }
    return true;
});

defineRule("newdigit", (value) => {
    if (!/^[0-9]\d*?$/.test(value)) {
        return "Please enter numeric values only";
    }
    
    if ((value < 1)) {
        return "Please enter postive numeric values only";
    }
    return true;
});


defineRule("nin", (value) => {
    if (value && !/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$/.test(value)) {
        return "Please enter valid natinal identity number";
    }
    return true;
});

defineRule("course_url", (value) => {
    if (!value || !/^https?:\/\/\S+\.\S+$/.test(value)) {
        return "Please enter a valid URL";
    }
    return true;
});
defineRule("requiredArray", (value) => {
    if (!value || value.length === 0) {
        return "Please select at least one option";
    }
    return true;
});



  

