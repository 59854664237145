import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      user: {},
      id: "",
      uuid: "",
      title: "",
      isHideMenu: false,
      parentId: "",
      userPage: 1,
      userStatus: "",
      doctorStatus: "",
      doctorPage: 1,
      scanType: "USER",
      qualificationLang: "en",
      expertiseLang: "en",
      faqLang: "en",
      vitalLang: "en",
      staticLang: "en",
      serviceLang: "en",
      onboardingLang: "en",
      HealthLang: "en",
      SubscriptionLang: "en",
      CourseLang: "en",
      doctorDetailsTab:"DOCTOR_DETAILS",
      userDetailsTab:"USER_DETAILS"
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setParentId(state, payload) {
      state.parentId = payload;
    },
    setIsHideMenu(state, payload) {
      state.isHideMenu = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUserDetailsTab(state, payload) {
      state.userDetailsTab = payload;
    },
    setUserPage(state, payload) {
      state.userPage = payload;
    },
    setUserStatus(state, payload) {
      state.userStatus = payload;
    },
    setDoctorStatus(state, payload) {
      state.doctorStatus = payload;
    },
    setDoctorDetailsTab(state, payload) {
      state.doctorDetailsTab = payload;
    },
    setDoctorPage(state, payload) {
      state.doctorPage = payload;
    },
    setScanType(state, payload) {
      state.scanType = payload;
    },
    setFaqLang(state, payload) {
      state.faqLang = payload;
    },
    setQualificationLang(state, payload) {
      state.qualificationLang = payload;
    },
    setExpertiseLang(state, payload) {
      state.expertiseLang = payload;
    },
    setVitalLang(state, payload) {
      state.vitalLang = payload;
    },
    setStaticLang(state, payload) {
      state.staticLang = payload;
    },
    setServiceLang(state, payload) {
      state.serviceLang = payload;
    },
    setOnboardingLang(state, payload) {
      state.onboardingLang = payload;
    },
    setHealthLang(state, payload) {
      state.HealthLang = payload;
    },
    setCourseLang(state, payload) {
      state.CourseLang = payload;
    },
    setSubscriptionLang(state, payload) {
      state.SubscriptionLang = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },

  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getTitle(state) {
      return state.title;
    },
    getId(state) {
      return state.id;
    },
    getParentId(state) {
      return state.parentId;
    },
    getIsHideMenu(state) {
      return state.isHideMenu;
    },
    getUuid(state) {
      return state.uuid;
    },
    getUserId(state) {
      return state.userId;
    },

    getUserDetailsTab(state) {
      return state.userDetailsTab;
    },
    getUserPage(state) {
      return state.userPage;
    },
    getUserStatus(state) {
      return state.userStatus;
    },
    getDoctorDetailsTab(state) {
      return state.doctorDetailsTab;
    },
    getDoctorPage(state) {
      return state.doctorPage;
    },
    getDoctorStatus(state) {
      return state.doctorStatus;
    },
    getscanType(state) {
      return state.scanType;
    },
    getFaqLang(state) {
      return state.faqLang;
    },
    getQualificationLang(state) {
      return state.qualification;
    },
    getExpertiseLang(state) {
      return state.expertiseLang;
    },
    getVitalLang(state) {
      return state.vitalLang;
    },
    getStaticLang(state) {
      return state.staticLang;
    },
    getServiceLang(state) {
      return state.serviceLang;
    },
    getOnboardingLang(state) {
      return state.onboardingLang;
    },
    getHealthLang(state) {
      return state.HealthLang;
    },
    getCourseLang(state) {
      return state.CourseLang;
    },
    getSubscriptionLang(state) {
      return state.SubscriptionLang;
    },
    getStatus(state) {
      return state.status;
    },
  },
  actions: {},
});

export default store;